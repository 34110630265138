/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IExercise } from '../domains/Exercise';
import {
  ICreateTraining,
  IGetTrainings,
  IResponseDaysOfWeek,
  ITraining,
} from '../domains/Training';
import { api } from './api';

class TrainingsService {
  private apiService = api;

  public async getTrainingsByUsernameAndDay({ username, day }: IGetTrainings) {
    return this.apiService.get<ITraining[]>(`trainings/${username}/${day}`);
  }

  public async getTrainingsOfWeek(username: string) {
    return this.apiService.get<IResponseDaysOfWeek>(
      `trainings/${username}/week`,
    );
  }

  public async createTraining({
    user_id,
    exercise_id,
    day_of_week,
  }: ICreateTraining) {
    return this.apiService.post<ITraining>('trainings', {
      user_id,
      exercise_id,
      day_of_week,
    });
  }
}

export { TrainingsService };
