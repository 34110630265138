/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { IExercise, IMuscleGroup } from '../domains/Exercise';
import { api } from './api';

class ExercisesService {
  private apiService = api;

  public async getMuscleGroups() {
    return this.apiService.get<IMuscleGroup[]>('exercises/muscle_groups');
  }

  public async getExercisesOfGym() {
    return this.apiService.get<IExercise[]>('exercises');
  }

  public async createExercise(data: Omit<IExercise, 'id'>) {
    return this.apiService.post(`exercises`, {
      name: data.name,
      muscle_groups: data.muscle_groups,
      description: data.description,
    });
  }

  public async updateExercise(data: IExercise) {
    return this.apiService.put(`exercises/${data.id}`, {
      name: data.name,
      description: data.description,
      muscle_groups: data.muscle_groups,
    });
  }
}

export { ExercisesService };
