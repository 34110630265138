/* eslint-disable no-unused-expressions */
import React, { HtmlHTMLAttributes, useCallback, useState } from 'react';
import { CustomDialog } from 'react-st-modal';

import {
  Container,
  ContainerImg,
  ContainerTitle,
  Subtitle,
  Title,
} from './styles';

import noMidia from '../../assets/no-midia.png';
import { ButtonRemove } from './components/ButtonRemove';
import { ButtonChange } from './components/ButtonChange';
import { IExerciseMuscleGroup } from '../../domains/Exercise';

interface CardUserProps extends HtmlHTMLAttributes<HTMLDivElement> {
  exerciseName: string;
  muscleGroups: IExerciseMuscleGroup[];
  disableOptHover?: boolean;
  functionChangeButton?: any;
  functionRemoveButton?: any;
  id?: string;
  thumbnail_url?: string | undefined;
}

const CardExercise: React.FC<CardUserProps> = ({
  exerciseName,
  muscleGroups,
  id,
  thumbnail_url,
  disableOptHover = false,
  functionChangeButton,
  functionRemoveButton,
  ...rest
}) => {
  const [hover, setHover] = useState(false);

  const handleSetHover = useCallback((state: boolean) => {
    setHover(state);
  }, []);

  return (
    <Container
      onMouseEnter={() => {
        !disableOptHover ? handleSetHover(true) : '';
      }}
      onMouseLeave={() => {
        !disableOptHover ? handleSetHover(false) : '';
      }}
      {...rest}
    >
      <ContainerImg>
        <img src={thumbnail_url || noMidia} alt="midia exercise" />
      </ContainerImg>
      <ContainerTitle>
        <Title>{exerciseName}</Title>
        <Subtitle>
          {muscleGroups
            .sort((a, b) => {
              if (a.is_main) return 1;
              return 0;
            })
            .map(group => ` ${group.muscle_group?.name}`)
            .toString()}
        </Subtitle>
      </ContainerTitle>
      {hover && <ButtonRemove onClick={functionRemoveButton} />}
      {hover && <ButtonChange onClick={functionChangeButton} />}
    </Container>
  );
};

export { CardExercise };
