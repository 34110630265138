import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { HeaderModal1 } from '../../components/HeaderModal1';
import { InputTextArea } from '../../components/InputTextArea';
import { Select, optionProps } from '../../components/Select';
import { SeparateLabel } from '../../components/SeparateLabel';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';

import exercise from './assets/exercise.jpg';

import {
  ButtonImg,
  Container,
  ContainerCardsMuscleGroups,
  ContainerImg,
  ContainerInline,
  FormContainer,
  Info,
  Input,
  Submit,
} from './styles';
import { ExercisesService } from '../../services/ExercisesService';
import { CardMuscleGroup } from '../../components/CardMuscleGroup';
import { useModal } from '../../hooks/modal';
import { IExerciseMuscleGroup, IMuscleGroup } from '../../domains/Exercise';

interface IFormProps {
  name: string;
  muscle_groups: IExerciseMuscleGroup[];
  description: string;
}

const AddExercise: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { removeModal } = useModal();
  const exercisesService = new ExercisesService();

  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [listMuscleGroups, setListMuscleGroups] = useState<
    IExerciseMuscleGroup[]
  >([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IFormProps) => {
      try {
        setLoading(true);

        if (listMuscleGroups.length < 1) {
          addToast({
            type: 'error',
            title: 'Pelo menos um grupo muscular deve ser cadastrado.',
          });
          setLoading(false);
          return;
        }

        if (!listMuscleGroups.some(m => m.is_main)) {
          addToast({
            type: 'error',
            title: 'Pelo menos um grupo muscular deve ser o principal.',
          });
          setLoading(false);
          return;
        }

        if (listMuscleGroups.filter(m => m.is_main).length > 1) {
          addToast({
            type: 'error',
            title: 'Apenas um grupo muscular pode ser o principal.',
          });
          setLoading(false);
          return;
        }

        formRef.current?.setErrors({});

        const schema = Yup.object({
          name: Yup.string().required('Campo obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await exercisesService.createExercise({
          name: data.name,
          muscle_groups: listMuscleGroups,
          description: data.description || undefined,
        });

        addToast({
          type: 'success',
          title: 'Exercício criado com sucesso',
        });

        setLoading(false);
        removeModal();
      } catch (err: any) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 406) {
          addToast({
            type: 'error',
            title: 'Exercicio com o mesmo nome ja cadastrado.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criação do exercício',
          description:
            'Houve um erro na criação do exercicio. Tente novamente mais tarde.',
        });
      }
    },
    [addToast, exercisesService, listMuscleGroups, removeModal],
  );

  const addItemMuscleGroup = useCallback(
    (selected: optionProps) => {
      if (
        !listMuscleGroups.find(
          group => group.muscle_group_id === selected.value,
        )
      ) {
        const groupfinded = muscleGroups.find(
          finded => finded.id === selected.value,
        );
        if (groupfinded) {
          setListMuscleGroups(element => [
            ...element,
            {
              name: selected.label,
              muscle_group_id: selected.value,
              is_main: false,
            } as IExerciseMuscleGroup,
          ]);
        }
      }
    },
    [listMuscleGroups, muscleGroups],
  );

  const handleChangeSelect = useCallback(
    (e: any) => {
      const option: optionProps = e;
      if (option) {
        addItemMuscleGroup(option);
      }
    },
    [addItemMuscleGroup],
  );

  const getMuscleGroups = useCallback(() => {
    exercisesService
      .getMuscleGroups()
      .then(response => {
        setMuscleGroups(response.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos grupos musculares.',
          description:
            'Caso o problema persista, entre em contato com o administrador do sistema',
        });
      });
  }, []);

  useEffect(() => {
    getMuscleGroups();
  }, []);

  return (
    <Container>
      <HeaderModal1 title="Novo exercício" />

      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        {/* <InputFile name="inputFile" /> */}

        <SeparateLabel label="Informações gerais" />

        <ContainerInline>
          <Input name="name" label="Nome" placeholder="" />
          <Select
            clearValueChange
            name="muscle_group"
            label="Grupo muscular"
            options={muscleGroups.map(group => ({
              value: group.id as string,
              label: group.name as string,
            }))}
            defaultValue={{ value: '' }}
            placeholder="Grupo muscular"
            onChange={handleChangeSelect}
          />
        </ContainerInline>

        {listMuscleGroups.length > 0 &&
        !listMuscleGroups.find(mg => mg.is_main) ? (
          <Info text="Clique em um grupo muscular para torná-lo principal (usado para ordenação dos exercicios)." />
        ) : (
          <></>
        )}

        <SeparateLabel label="Grupos musculares" />

        <ContainerCardsMuscleGroups>
          {listMuscleGroups.map(group => {
            return (
              <CardMuscleGroup
                key={group.id}
                label={group?.name as string}
                isMain={group.is_main ?? false}
                onClickClose={() =>
                  setListMuscleGroups(groups => [
                    ...groups.filter(current => current.id !== group.id),
                  ])
                }
                onClickSetMain={() =>
                  setListMuscleGroups(groups => [
                    ...groups.map(current => {
                      if (current.id === group.id) {
                        // eslint-disable-next-line no-param-reassign
                        current.is_main = !current.is_main;
                      }
                      return current;
                    }),
                  ])
                }
              />
            );
          })}
        </ContainerCardsMuscleGroups>

        <SeparateLabel label="Informações adicionais" />
        <InputTextArea name="description" label="Descrição" placeholder="" />
        <Submit
          name="submit"
          value="Criar exercício"
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit}
        />
      </FormContainer>
    </Container>
  );
};

export { AddExercise };
