/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IGym } from '../domains/Gym';
import { api } from './api';

class GymsService {
  private apiService = api;

  public async getGyms() {
    return this.apiService.get<IGym[]>(`gyms`);
  }

  public async unlock(gym_id: string) {
    return this.apiService.patch<void>(`gyms/unlock`, {
      gym_id,
    });
  }
}

export { GymsService };
export type { IGym };
