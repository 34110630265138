/* eslint-disable react-hooks/rules-of-hooks */
import { FormHandles } from '@unform/core';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { format } from 'date-fns';

import { ButtonOptions } from '../../components/ButtonOptions';
import { InputSearch } from '../../components/InputSearch';
import { Select } from '../../components/Select';
import { Painel } from '../../components/Painel';
import { ButtonsDaysOfWeek } from '../../components/ButtonsDaysOfWeek';

import { AddExercisesInTraining } from '../../modais/addExercisesInTraining';
import { ChangeTrainingUser } from '../../modais/changeTrainingUser';
import { ChangeDataUser } from '../../modais/changeDataUser';

import { api } from '../../services/api';

import {
  Container,
  Content,
  Form,
  Card,
  ContainerProfile,
  ContainerAvatar,
  ContainerDataProfile,
  ContainerName,
  ContainerLastExercise,
  ContainerButtonFloat,
} from './styles';

import noAvatar from '../../assets/no-avatar.jpg';
import key0 from '../../assets/access0.svg';
import key1 from '../../assets/access1.svg';
import key2 from '../../assets/access2.svg';
import key4 from '../../assets/access.svg';

import addExerciseImg from './assets/addExercise.svg';

import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { formatName } from '../../utils/formatName';
import { ButtonCircle } from '../../components/ButtonCircle';
import { TrainingsService } from '../../services/TrainingsService';
import { ExercisesService } from '../../services/ExercisesService';
import { IExercise, IMuscleGroup } from '../../domains/Exercise';

const key = [key0, key1, key2, key4];

interface IUserProps {
  avatar_url: string | undefined;
  fullname: string;
  level_access: number;
  id: string;
  is_active: boolean;
}

interface ITraining {
  id: string;
  exercise: IExercise;
}

const UserExercises: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const trainingsService = new TrainingsService();
  const exercisesService = new ExercisesService();

  const [loading, setLoading] = useState(true);
  const [usernameUser, setUsernameUser] = useState('');
  const [user, setUser] = useState<IUserProps>();
  const [lastTraining, setLastTraining] = useState<string>();
  const [lastDayWithTrainings, setLastDayWithTrainings] = useState(0);

  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [filter, setFilter] = useState<ITraining[]>([]);
  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [selectValue, setSelectValue] = useState('');

  const { addToast } = useToast();
  const { addModal, modals } = useModal();
  const { day, username } = useParams();

  const getUserData = useCallback(() => {
    if (username) {
      setUsernameUser(username);
    }
    api
      .get(`users/gym/${username}`)
      .then(response => {
        setUser(response.data.user);
        if (response.data.lastTraining) {
          setLastTraining(
            format(new Date(response.data.lastTraining), 'dd/MM/yyyy'),
          );
        } else {
          setLastTraining('--/--/----');
        }
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos dados do usuário.',
          description: err?.response?.status,
        });
      });
  }, [addToast, username]);

  const getTrainings = useCallback(() => {
    formRef.current
      ?.getFieldRef('muscleGroup')
      .setValue({ label: 'Todos', value: '' });

    setLoading(true);

    trainingsService
      .getTrainingsByUsernameAndDay({ username, day })
      .then(response => {
        setTrainings(response.data);
        setFilter(response.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos treinos.',
          description: err?.response?.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, day, trainingsService, username]);

  const getTrainingsOfWeek = useCallback(() => {
    if (username) {
      trainingsService
        .getTrainingsOfWeek(username)
        .then(response => {
          if (response.data.groups.length === 0) {
            setLastDayWithTrainings(0);
            return;
          }

          const last = Math.max.apply(
            0,
            response.data.groups.map(v => v.index),
          );

          setLastDayWithTrainings(last);
        })
        .catch(err => {
          addToast({
            type: 'error',
            title: 'Erro na busca dos treinos da semana.',
            description: err?.response?.status,
          });
        });
    }
  }, [addToast, trainingsService, username]);

  const getMuscleGroups = useCallback(() => {
    exercisesService
      .getMuscleGroups()
      .then(response => {
        setMuscleGroups([{ id: '', name: 'Todos' }, ...response.data]);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos grupos musculares.',
          description:
            'Caso o proplema persista, entre em contato com o administrador do sistema',
        });
      });
  }, [addToast, exercisesService]);

  const handleFilter = useCallback(() => {
    setFilter(trainings);
    const input = formRef.current?.getFieldValue('searchTrainings') as string;

    const values = trainings.filter(current => {
      if (selectValue) {
        if (
          current.exercise.muscle_groups.some(
            group =>
              group.muscle_group_id === selectValue &&
              current.exercise.name.toLowerCase().indexOf(input.toLowerCase()) >
                -1,
          )
        ) {
          return true;
        }
      } else if (
        current.exercise.name.toLowerCase().indexOf(input.toLowerCase()) > -1
      ) {
        return true;
      }
      return false;
    });

    setFilter(values);
  }, [selectValue, trainings]);

  const handleChangeSelectForm = useCallback((e: any) => {
    setSelectValue(e.value);
  }, []);

  const handleRemoveTraining = useCallback(
    async (training_id: string) => {
      try {
        await api.delete(`trainings/${training_id}`);

        setTrainings(trainings.filter(training => training.id !== training_id));
        setFilter(filter.filter(training => training.id !== training_id));

        addToast({
          type: 'success',
          title: 'Exercicio removido.',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na remoção',
          description:
            'Tente novamente. Caso o problema persista entre em contato com a administração do sistema.',
        });
      }
    },
    [addToast, filter, trainings],
  );

  useEffect(() => {
    handleFilter();
  }, [handleFilter, selectValue]);

  // useEffect(() => {
  //   getUserData();
  // }, []);

  useEffect(() => {
    getMuscleGroups();
  }, []);

  useEffect(() => {
    getTrainings();
    getUserData();
  }, [modals]);

  useEffect(() => {
    setLoading(true);
    getTrainings();
    getTrainingsOfWeek();
  }, [day]);

  return (
    <Painel loading={loading}>
      <Container>
        <ContainerProfile>
          <ContainerAvatar>
            <img src={user?.avatar_url || noAvatar} alt="avatar user" />
          </ContainerAvatar>

          <ContainerDataProfile>
            <ContainerName>
              <span>{formatName(user?.fullname || '')}</span>
              <img
                src={
                  key[
                    user?.level_access
                      ? user?.level_access > 3
                        ? 3
                        : user.level_access
                      : 0
                  ]
                }
                alt="access level user"
              />
            </ContainerName>
            <ContainerLastExercise>
              <span>Ultimo treino realizado:</span>
              <span>{lastTraining}</span>
            </ContainerLastExercise>
            <ButtonOptions
              name="config"
              value="Configurações"
              onClick={() =>
                addModal(
                  <ChangeDataUser
                    avatar_url={user?.avatar_url}
                    id={user?.id || ''}
                    name={formatName(user?.fullname || '')}
                    level_access={user?.level_access || 0}
                    is_active={user?.is_active || true}
                  />,
                )
              }
            />
          </ContainerDataProfile>
        </ContainerProfile>

        <ButtonsDaysOfWeek
          value="day"
          name="days"
          day={Number(day)}
          username={usernameUser}
          lastTraining={lastDayWithTrainings}
        />

        <Form ref={formRef} onSubmit={() => console.log()}>
          <InputSearch name="searchTrainings" onChange={handleFilter} />
          <div className="line2">
            <Select
              name="muscleGroup"
              options={muscleGroups.map(group => ({
                label: group.name as string,
                value: group.id as string,
              }))}
              placeholder="Grupo muscular"
              onChange={handleChangeSelectForm}
              blurInputOnSelect
            />
          </div>
        </Form>

        <Content>
          {filter.map(training => (
            <Card
              key={training.id}
              id={training.id}
              exerciseName={training.exercise.name}
              muscleGroups={training.exercise.muscle_groups}
              thumbnail_url={training.exercise.thumbnail_url}
              functionChangeButton={() =>
                addModal(<ChangeTrainingUser training_id={training.id} />)
              }
              functionRemoveButton={() => handleRemoveTraining(training.id)}
            />
          ))}
        </Content>

        <ContainerButtonFloat>
          <ButtonCircle
            name="button"
            value="Adicionar exercício"
            image={addExerciseImg}
            onClick={() =>
              addModal(
                <AddExercisesInTraining
                  user_avatar={user?.avatar_url}
                  user_id={user?.id || ''}
                  training_number={Number(day)}
                  exercises_already_registered_of_day={trainings.map(
                    training => training.exercise,
                  )}
                />,
              )
            }
          />
        </ContainerButtonFloat>
      </Container>
    </Painel>
  );
};

export { UserExercises };
